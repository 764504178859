import bannerImg from "./../../assets/layout-header.png";

import { useCallback, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import LoadingComponent from "./../../components/LoadingComponent";
import { Link } from "react-router-dom";
import { getUID, getStoredResponseSheetName } from "../../utils/authUtils";
import {
  Candidate,
  CandidateCheckInStatus,
} from "../../../api/src/interfaces/Candidate";
import CustomButton from "../../components/CustomButton";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
import { getSettings } from "../../services/getSettings";
import { SETTINGS } from "../../../api/src/interfaces/sheets";
import useCandidate from "../../hooks/useCandidate";

function CustomField(props: {
  style?: React.CSSProperties;
  title: string;
  children: any;
}) {
  const extraStyles = props.style ? props.style : {};
  return (
    <div
      style={{
        marginBottom: "10px",
        width: "100%",
        textAlign: "center",
        ...extraStyles,
      }}
    >
      <div style={{ color: "white" }}>{props.title}</div>
      <div
        style={{
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "10px",
          color: "var(--primary-color)",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

export default function PhoneInCongraz() {
  const [isLoading, setIsLoading] = useState(true);
  const [openJobInfo, setOpenJobInfo] = useState(false);

  const navigate = useNavigate();
  let [urlParams, setUrlParams] = useSearchParams();
  const candidateData = useCandidate();
  const [settingsData, setSettingsData] = useState<SETTINGS | null>(null);

  const processInterviewDateTime = (idt: string | any) => {
    if (!idt) return { date: "", time: "" };
    let arr = idt.split("-").map((x: string) => String(x).trim());
    const [date, timeStart] = arr[0].split(" ").filter((x: string) => x);
    const timeEnd = arr[1];
    return { date, time: `${timeStart} - ${timeEnd}` };
  };

  const jobInfoOnClick = useCallback(() => {
    if (!settingsData) return;
    window.open(settingsData.setting_pwa_job_info);
  }, [settingsData]);

  useEffect(() => {
    const run = async () => {
      const { data: settings, error } = await getSettings(
        getStoredResponseSheetName()
      );

      setSettingsData(settings);
    };
    run()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);
  return isLoading ? (
    <LoadingComponent style={{ gridColumn: "2 / 3" }}></LoadingComponent>
  ) : (
    <>
      {/* Job Info Dialog */}
      <Dialog
        open={openJobInfo}
        onClose={() => setOpenJobInfo(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Job Info</DialogTitle>
        <DialogContent>
          <div style={{ whiteSpace: "pre-line" }} id="alert-dialog-description">
            {settingsData ? (
              String(settingsData.setting_pwa_job_info)
            ) : (
              <LoadingComponent></LoadingComponent>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton
            style={{ backgroundColor: "#ffdeec" }}
            onClick={() => setOpenJobInfo(false)}
          >
            Close
          </CustomButton>
        </DialogActions>
      </Dialog>
      <div
        style={{
          gridArea: "2 / 2 / 3 / 3",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: "var(--focus-primary-color)",
          }}
        >
          <h1 style={{ color: "white", fontSize: "32px" }}>Congratulations!</h1>
        </div>
        <div
          style={{
            borderRadius: "30px",
            border: "3px solid white",
            backgroundColor: "var(--focus-primary-color)",
            padding: "40px 30px",
            marginLeft: "10px",
            marginRight: "10px",
            // height: "100%",
            color: "black",
          }}
        >
          <div
            style={{
              marginBottom: "10px",
              textAlign: "center",
              fontSize: "large",
              color: "white",
            }}
          >
            You have been shortlisted for
            <br />
            "Phone In Interview"
          </div>
          <img
            alt="ticket-img"
            style={{
              maxHeight: "200px",
              maxWidth: "100%",
              marginBottom: "20px",
            }}
            src={bannerImg}
          ></img>
          <div
            style={{
              color: "#ffabdb",
              marginBottom: "10px",
              width: "100%",
              textAlign: "center",
            }}
          >
            Interview Details
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CustomField title="Time">
              {candidateData ? (
                processInterviewDateTime(candidateData?.interviewDateTime).time
              ) : (
                <LoadingComponent></LoadingComponent>
              )}
            </CustomField>
            <CustomField title="Date" style={{ marginBottom: "20px" }}>
              {candidateData ? (
                processInterviewDateTime(candidateData?.interviewDateTime).date
              ) : (
                <LoadingComponent></LoadingComponent>
              )}
            </CustomField>
            <CustomButton
              style={{
                color: "white",
                backgroundColor: "var(--primary-color)",
                fontSize: "large",
              }}
              onClick={jobInfoOnClick}
            >
              Job Info
            </CustomButton>
          </div>
        </div>
      </div>
      <div
        style={{
          gridColumn: "2 / 3",
          margin: "0 10px",
          marginBottom: "20px",
          alignItems: "end",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Link
          to="/consultant"
          style={{ textDecoration: "none", width: "100%" }}
        >
          <CustomButton
            onClick={() => {}}
            style={{ backgroundColor: "white", color: "var(--primary-color)" }}
          >
            Click here to proceed
          </CustomButton>
        </Link>
      </div>
    </>
  );
}
