import rootUrl from "../APIRoot";

export default async function sendWhatsappMessage(payload: {
  text: string;
  phone: string;
}): Promise<{
  data: any;
  error: any;
}> {
  // Uncomment in production
  let req = await fetch(`${rootUrl}/whatsapp/message`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  let { data, error } = await req.json();
  return { data, error };
  // await new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve(12);
  //   }, 3000);
  // });
  // return { data: 1, error: 0 };
}
