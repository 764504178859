import { Link, Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import banner from "../assets/banner.png";
import { getUID, logOff } from "../utils/authUtils";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import useCandidate from "../hooks/useCandidate";

const MenuList: { text: string; faIcon: string[]; link: string }[] = [
  {
    text: "Job Info",
    faIcon: ["fas", "question"],
    link: "/congratualations",
  },
  // {
  //     text: "Get Started",
  //     faIcon: ["fas", "fa-flag-checkered"],
  //     link: "/checkin",
  // },
  // {
  //     text: "Your Consultant",
  //     faIcon: ["fas", "question"],
  //     link: "/consultant",
  // },
  // {
  //     text: "Join Google Meet",
  //     faIcon: ["fas", "handshake"],
  //     link: "/joinsoon",
  // },
  // // { text: "Join GMeet", faIcon: ["fas", "house"], link: "/gmeet" },
  // {
  //     text: "Complete Interview",
  //     faIcon: ["fas", "list"],
  //     link: "/interviewCompletedPrompt",
  // },
  // // { text: "Complete Interview", faIcon: ["fas", "house"], link: "/interviewerInput" },
  // {
  //     text: "Rate your interview",
  //     faIcon: ["fas", "star"],
  //     link: "/interviewCompleted",
  // },
  // // { text: "Feedback", faIcon: ["fas", "house"], link: "/feedback" },
  // // { text: "End of Interview", faIcon: ["fas", "house"], link: "/endOfInterview" },
];

export default function WalkinLayout() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const candidate = useCandidate();
  const navigate = useNavigate();
  const toggleDrawer = (open: boolean) => {
    setOpenDrawer(open);
  };

  return (
    <div
      style={{
        color: "white",
        display: "grid",
        gridTemplateColumns: "20px 1fr 20px",
        gridTemplateRows: "auto auto 1fr",
        height: "100%",
        backgroundColor: "var(--primary-color)",
        paddingTop: "20px",
        fontWeight: "bold",
      }}
    >
      <div></div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "40px 1fr 100px",
          alignItems: "center",
        }}
      >
        <div>
          <IconButton onClick={() => toggleDrawer(true)}>
            <FontAwesomeIcon
              style={{ color: "white" }}
              icon={["fas", "bars"]}
            ></FontAwesomeIcon>
          </IconButton>
          <Drawer
            anchor={"left"}
            open={openDrawer}
            onClose={() => toggleDrawer(false)}
          >
            <Box
              sx={{ width: 250 }}
              onClick={() => toggleDrawer(false)}
              onKeyDown={() => toggleDrawer(false)}
            >
              <div
                style={{
                  padding: "20px",
                  color: "white",
                  backgroundColor: "var(--primary-color)",
                }}
              >
                <img
                  onClick={() => {
                    toggleDrawer(false);
                    setTimeout(() => navigate("/"), 500);
                  }}
                  src={banner}
                  style={{ maxWidth: "100%" }}
                  alt="side-banner"
                ></img>
                <div>Welcome!</div>
                <div style={{ fontWeight: "bold" }}>
                  {candidate ? candidate.name : ""}
                </div>
                {candidate ? (
                  <>
                    <div
                      onClick={() => {
                        logOff();
                        navigate("/");
                      }}
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Log off
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <Divider></Divider>
              <List>
                {MenuList.map(({ text, faIcon, link }) => (
                  <ListItem key={`menu-list-${text}`} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        toggleDrawer(false);
                        setTimeout(() => navigate(link), 500);
                      }}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon size="xl" icon={faIcon as IconProp} />
                      </ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={banner} alt="decorTop" style={{ maxHeight: "60px" }} />
        </div>
        <div>
          <Link to="/consultant" style={{ textDecoration: "none" }}>
            <Button
              sx={{
                color: "#25D366",
                backgroundColor: "white !important",
              }}
              variant="contained"
              startIcon={
                <FontAwesomeIcon icon={["fab", "whatsapp"]}></FontAwesomeIcon>
              }
            >
              Help
            </Button>
          </Link>
        </div>
      </div>
      <div></div>
      <Outlet></Outlet>
    </div>
  );
}
