import React, { useState, useEffect, useCallback } from "react";
import CustomButton from "../../components/CustomButton";
import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import CustomSelect from "../../components/CustomSelect";
import { Link, useNavigate } from "react-router-dom";
import updateCandidate from "../../services/updateCandidate";
import { getStoredResponseSheetName, getUID } from "../../utils/authUtils";
import { Dialog } from "@mui/material";
import ErrorDialog from "../../components/ErrorDialog";
import useCandidate from "../../hooks/useCandidate";
import useSettings from "../../hooks/useSettings";
import useConsultants from "../../hooks/useConsultants";
import LoadingComponent from "../../components/LoadingComponent";
export default function PhoneIn5MinPreInterview() {
  const navigate = useNavigate();
  const [joinTime, setJoinTime] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const candidate = useCandidate();
  const consultants = useConsultants();

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = useCallback(() => {
    if (submitting) return;
    if (!candidate || !consultants) return;
    setSubmitting(true);
    if (!joinTime) {
      setErrMsg("Please select a duration.");
      setSubmitting(false);
      return;
    }

    window.open(
      `${consultants?.whatsappHref}&text=I am ${candidate.name} and I am ready for pre-interview in ${joinTime}`
    );

    updateCandidate(getStoredResponseSheetName(), getUID(), {
      interviewJoinTime: String(joinTime),
    })
      .then(({ data, error }) => {
        if (error) throw new Error(error);
        navigate("/willCallYou");
        setSubmitting(false);
      })
      .catch((err) => {
        console.error(err);
        setErrMsg(err.message);
        setSubmitting(false);
      });
  }, [candidate, consultants, joinTime]);
  return (
    <SimpleIphonePromptLayout cardStyle={{ backgroundColor: "#fff2fa" }}>
      {candidate && consultants ? (
        <>
          <ErrorDialog errMsg={errMsg} setErrMsg={setErrMsg}></ErrorDialog>
          <div
            style={{
              display: "grid",
              gridTemplateRows: "auto 1fr auto auto",
              height: "100%",
              width: "100%",
              rowGap: "10px",
              alignItems: "center",
            }}
          >
            <CustomButton
              style={{
                color: "var(--primary-color)",
                backgroundColor: "#ffdeec",
              }}
            >
              5 Minute Job Brief and Tips
            </CustomButton>
            <div
              style={{
                padding: "20px 0",
                fontWeight: "bold",
                fontSize: "32px",
              }}
            >
              How soon may
              <br />
              you join our
              <br />
              "5 Minutes <br />
              Job Brief and Tips" ?<br />
            </div>
            <CustomSelect
              style={{
                color: "white",
                backgroundColor: "var(--focus-primary-color)",
              }}
              onChange={(e: any) => {
                let index = e.nativeEvent.target.selectedIndex;
                let selectedText = e.nativeEvent.target[index].text;
                setJoinTime(e.target.value);
              }}
              choices={[
                { key: "5", value: "5 minute" },
                { key: "10", value: "10 minute" },
                { key: "20", value: "20 minute" },
                { key: "30", value: "30 minute" },
              ]}
            ></CustomSelect>
            {/* <Link style={{ width: "100%", textDecoration: "none" }} to="/willCallYou"> */}
            <CustomButton
              style={{
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={onSubmit}
            >
              {submitting ? "Submitting..." : "Submit"}
            </CustomButton>
            {/* </Link> */}
          </div>
        </>
      ) : (
        <LoadingComponent></LoadingComponent>
      )}
    </SimpleIphonePromptLayout>
  );
}
