export default function CustomButton(props: {
  onClick?: any;
  style?: React.CSSProperties;
  children?: any;
}) {
  const extraStyles = props.style ? props.style : {};
  return (
    <div
      onClick={props.onClick}
      style={{
        cursor: "pointer",
        textAlign: "center",
        color: "var(--primary-color)",
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "10px",
        width: "100%",
        ...extraStyles,
      }}
    >
      {props.children}
    </div>
  );
}
