import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import layoutHeaderImg from "../../../assets/layout-header.png";
import { useSearchParams } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
export default function PhoneInActualInterviewWellDone() {
  const [rating, setRating] = useState(3);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  let [urlParams, setUrlParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <>
      <div style={{ gridArea: "2 / 1 / 3 / 4" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={layoutHeaderImg}
          alt="layoutHeaderImg"
          style={{ maxWidth: "100%" }}
        ></img>
        </div>
      </div>
      <div
        style={{ gridColumn: "2 / 3", gridRow: "2 / 4" }}
        className="zoom-out-anim"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "white",
          }}
        >
          <div style={{ fontSize: "4rem", textAlign: "center" }}>
            Well Done!
          </div>
          <br />
          <div style={{ fontSize: "3rem", textAlign: "center" }}>You have</div>
          <div style={{ fontSize: "3rem", textAlign: "center" }}>completed</div>
          <div
            style={{
              fontSize: "3rem",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            your interview.
          </div>
        </div>
      </div>
      <div style={{ gridColumn: "2 / 3" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "10px",
            width: "100%",
            gridColumn: "2 / 3",
            marginBottom: "10px",
          }}
        >
          <CustomButton onClick={() => navigate("/actual/rating")}>Next</CustomButton>
        </div>
      </div>
    </>
  );
}
