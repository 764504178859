import React, { useState, useEffect } from "react";
import { Candidate } from "../../api/src/interfaces/Candidate";
import { getCandidate } from "../services/getCandidate";
import { getStoredResponseSheetName, getUID } from "../utils/authUtils";
export default function useCandidate(forceRefetch = false) {
  const localStorageKey = "candidateData";
  const [candidate, setCandidate] = useState<Partial<Candidate> | null>(null);
  useEffect(() => {
    const run = async () => {
      const cache = localStorage.getItem(localStorageKey);
      if (cache && !forceRefetch) {
        const {data, lastUpdated} = JSON.parse(cache)
        if (new Date().getTime() - lastUpdated < 300000) {
          setCandidate(data);
          return;
        }
      }

      const { data, error } = await getCandidate(
        getStoredResponseSheetName(),
        getUID()
      );

      setCandidate(data[0]);
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({ data: data[0], lastUpdated: new Date().getTime() })
      );
    };

    run()
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return React.useMemo(() => candidate, [candidate]);
}
