import React, { useEffect, useState } from "react";
import HenryIMG from "./../assets/consultants/Henry.png";
import JackyIMG from "./../assets/consultants/Jacky.png";
import OscarIMG from "./../assets/consultants/Oscar.jpg";
import NatashaIMG from "./../assets/consultants/Natasha.png";
import PooiJinIMG from "./../assets/consultants/PooiJin.jpeg";
import StephenIMG from "./../assets/consultants/Stephen.png";
import ChenShinIMG from "./../assets/consultants/ChenShin.png";
import useCandidate from "./useCandidate";
export default function useConsultants() {
  const candidate = useCandidate();
  const [consultant, setConsultant] = useState<{
    name: string;
    imgSrc: string;
    phone: string;
    whatsappHref: string;
  } | null>(null);
  useEffect(() => {
    if (!candidate) return;
    let consultantInitial = "CS";
    if (candidate.statusInserted) {
      let matchResult = String(candidate.statusInserted).match(
        /INSERTED: (.*?):/
      );
      if (matchResult && matchResult[1]) {
        consultantInitial = matchResult[1];
      }
    }
    switch (String(consultantInitial).trim()) {
      case "HS":
        setConsultant({
          name: "Henry",
          imgSrc: HenryIMG,
          phone: "60127170835",
          whatsappHref: "https://api.whatsapp.com/send?phone=60127170835",
        });
        break;
      case "NC":
        setConsultant({
          name: "Natasha",
          imgSrc: NatashaIMG,
          phone: "60123788023",
          whatsappHref: "https://api.whatsapp.com/send?phone=60123788023",
        });
        break;
      case "SO":
        setConsultant({
          name: "Stephen",
          imgSrc: StephenIMG,
          phone: "60162285917",
          whatsappHref: "https://api.whatsapp.com/send?phone=60162285917",
        });
        break;
      case "JS":
        setConsultant({
          name: "Jacky",
          imgSrc: PooiJinIMG,
          phone: "60123788925",
          whatsappHref: "https://api.whatsapp.com/send?phone=60123788925",
        });
        break;
      case "OL":
        setConsultant({
          name: "Oscar",
          imgSrc: OscarIMG,
          phone: "60162284692",
          whatsappHref: "https://api.whatsapp.com/send?phone=60162284692",
        });
        break;
      case "CS":
        setConsultant({
          name: "Chen Shin",
          imgSrc: ChenShinIMG,
          phone: "601127518776",
          whatsappHref: "https://api.whatsapp.com/send?phone=601127518776",
        });
        break;
      default:
        setConsultant({
          name: "Chen Shin",
          imgSrc: ChenShinIMG,
          phone: "601127518776",
          whatsappHref: "https://api.whatsapp.com/send?phone=601127518776",
        });
    }
  }, [candidate]);

  return React.useMemo(() => {
    return consultant;
  }, [consultant]);
}
