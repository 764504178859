import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useSettings from "../../../hooks/useSettings";
import SimpleIphonePromptLayout from "../../../layouts/SimpleIphonePromptLayout";
import LoadingComponent from "../../../components/LoadingComponent";
import CustomButton from "../../../components/CustomButton";
import useCandidate from "../../../hooks/useCandidate";
import { processInterviewDateTime } from "../../../utils/formUtils";
import useConsultants from "../../../hooks/useConsultants";
import updateCandidate from "../../../services/updateCandidate";
import { getStoredResponseSheetName, getUID } from "../../../utils/authUtils";
import sendWhatsappMessage from "../../../services/sendWhatsappMessage";
export default function PhoneInActualInterview() {
  const navigate = useNavigate();
  const [meetInfo, setMeetInfo] = useState({
    meetLink: "https://meet.google.com/vpp-vhja-vwo",
    formLink:
      "https://docs.google.com/forms/d/1BB40-QpXTsW-OQ477h63EfRCVaQIKjVtzQit87q_1uo",
  });
  const candidate = useCandidate(true);
  const consultants = useConsultants();
  const settings = useSettings();
  const [loading, setLoading] = useState(true);

  const [pending, setPending] = useState(false);

  const onYes = async () => {
    if (pending) return;
    setPending(true);
    let interviewerPhoneNumber =
      settings?.setting_phonein_interviewer_phone_number;
    if (candidate?.interviewerPhoneNumberOverwrite) {
      interviewerPhoneNumber = candidate?.interviewerPhoneNumberOverwrite;
    }

    if (candidate?.whatsappBlastingStatus) {
      navigate("/actual/interviewCompletedPrompt");
      setPending(false);
      return;
    }
    await sendWhatsappMessage({
      text: `${candidate?.name} is ready for phone in interview!`,
      phone: String(consultants?.phone),
    });
    await sendWhatsappMessage({
      text: `${candidate?.name} is ready for phone in interview!`,
      phone: String(interviewerPhoneNumber),
    });

    await updateCandidate(getStoredResponseSheetName(), getUID(), {
      whatsappBlastingStatus: `${new Date()} Blasted to: ${
        consultants?.phone
      } & ${interviewerPhoneNumber}`,
    });
    navigate("/actual/interviewCompletedPrompt");
    setPending(false);
  };

  useEffect(() => {
    if (!settings || !candidate) return;
    setMeetInfo({
      meetLink: `https://api.whatsapp.com/send?phone=${String(
        settings.setting_phonein_interviewer_phone_number
      )}&text=I am ${candidate.name} and I am ready for phone in Interview`,
      formLink: String(settings.setting_pwa_gform_link),
    });

    setLoading(false);
  }, [settings, candidate]);
  return (
    <SimpleIphonePromptLayout>
      {loading ? (
        <LoadingComponent></LoadingComponent>
      ) : (
        <>
          <div
            style={{
              padding: "20px 0",
              fontWeight: "bold",
              fontSize: "32px",

              width: "100%",
            }}
          >
            Are you
            <br />
            available from
            <CustomButton
              style={{
                color: "var(--focus-primary-color)",
                width: "100%",
                border: "2px solid black",
              }}
            >
              {processInterviewDateTime(candidate?.interviewDateTime).time}
            </CustomButton>
            for a "Phone In
            <br />
            Interview"?
          </div>
          <div style={{ color: "grey" }}>Your "Phone In Interview" number:</div>
          <CustomButton
            style={{ color: "grey", border: "3px solid var(--primary-color)" }}
          >
            {/* {settings ? settings.setting_phonein_interviewer_phone_number : ""} */}
            {candidate ? candidate.phone : ""}
          </CustomButton>
          <div
            style={{
              display: "grid",
              gridGap: "10px",
              gridTemplateColumns: "1fr 1fr",
              width: "100%",
            }}
          >
            <CustomButton
              onClick={onYes}
              style={{
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
            >
              {pending ? "Informing interviewer..." : "Yes"}
            </CustomButton>
            <CustomButton
              onClick={() =>
                window.open(
                  `${consultants?.whatsappHref}&text=I am ${candidate?.name}, I like to reschedule my interview.`
                )
              }
              style={{
                backgroundColor: "#ececef",
                color: "var(--primary-color)",
              }}
            >
              Reschedule
            </CustomButton>
          </div>

          <div style={{ fontWeight: "normal", fontStyle: "italic" }}>
            <span style={{ color: "red" }}>*</span> Kindly return to this app
            after your interview!
          </div>
        </>
      )}
    </SimpleIphonePromptLayout>
  );
}
