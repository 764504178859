import Cookies from "universal-cookie";
import { Candidate } from "../../api/src/interfaces/Candidate";
export function storeUID(uid: string) {
  localStorage.setItem("uid", uid);
  const cookies = new Cookies();
  cookies.set("uid", uid, { path: "/" });
}

export function getUID() {
  return String(localStorage.getItem("uid"));
}

export function isAuth() {
  return getUID() && getUID() !== "null";
}

export function logOff() {
  localStorage.removeItem("uid");
  localStorage.removeItem("responseSheetName");
  const cookies = new Cookies();
  cookies.remove("uid", { path: "/" });
}

export function storeCandidate(candidate: Candidate) {
  localStorage.setItem("candidate", JSON.stringify(candidate));
}

export function clearStoredCandidate() {
  localStorage.removeItem("candidate");
}

export function storeResponseSheetName(rsn: string) {
  localStorage.setItem("responseSheetName", rsn);
}

export function getStoredResponseSheetName() {
  return String(localStorage.getItem("responseSheetName"));
}
