import SimpleIphonePromptLayout from "../../../layouts/SimpleIphonePromptLayout";
import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import updateCandidate from "../../../services/updateCandidate";
import useCandidate from "../../../hooks/useCandidate";
import { getStoredResponseSheetName, getUID } from "../../../utils/authUtils";
import LoadingComponent from "../../../components/LoadingComponent";
import useSettings from "../../../hooks/useSettings";
import ErrorDialog from "../../../components/ErrorDialog";
const StyledHeader = styled.div`
  font-size: 1.5rem;
  color: var(--primary-color);
`;

const focusStyle = {
  color: "#81838a",
  fontSize: "1.25rem",
  fontWeight: "bold",
};

export default function PhoneInActualInterviewInterviewerNamePrompt() {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    interviewerName: "",
    code: "",
  });
  const [errMsg, setErrMsg] = useState("");
  const candidate = useCandidate();
  const settings = useSettings();

  const onSubmit = async () => {
    if (submitting) return;
    if (!candidate || !settings) return;
    setSubmitting(true);

    if (
      String(settings.setting_walkin_checkin_code) !== String(formData.code)
    ) {
      setErrMsg("Interviewer code incorrect.");
      setSubmitting(false);
      return;
    }

    if (!formData.interviewerName) {
      setErrMsg("Interviewer name cannot be empty");
      setSubmitting(false);
      return;
    }

    updateCandidate(getStoredResponseSheetName(), getUID(), {
      interviewerName: formData.interviewerName,
    })
      .then(() => {
        setSubmitting(false);
        navigate("/actual/welldone");
      })
      .catch((err) => {
        console.error(err);
        setErrMsg(err);
      });
  };
  return (
    <SimpleIphonePromptLayout>
      {candidate && settings ? (
        <>
          <ErrorDialog errMsg={errMsg} setErrMsg={setErrMsg}></ErrorDialog>
          <StyledHeader>Name of Candidate</StyledHeader>
          <div style={focusStyle}>{candidate.name}</div>
          <CustomButton
            style={{
              color: "black",
              backgroundColor: "#fff2fa",
              width: "100%",
            }}
          >
            Interviewer Details
          </CustomButton>
          <StyledHeader>Name of Interviewer</StyledHeader>
          <CustomInput
            style={focusStyle}
            placeholder="Name of interviewer"
            onChange={(evt: any) =>
              setFormData((old: any) => ({
                ...old,
                interviewerName: evt.target.value,
              }))
            }
            type="text"
          ></CustomInput>
          <StyledHeader>Code</StyledHeader>
          <CustomInput
            type="text"
            placeholder="Interview Code"
            style={{ fontWeight: "bold" }}
            onChange={(evt: any) =>
              setFormData((old: any) => ({
                ...old,
                code: evt.target.value,
              }))
            }
          ></CustomInput>
          <CustomButton
            onClick={onSubmit}
            style={{
              backgroundColor: "var(--primary-color)",
              color: "white",
            }}
          >
            {submitting ? "Submitting..." : "Submit"}
          </CustomButton>
        </>
      ) : (
        <LoadingComponent></LoadingComponent>
      )}
    </SimpleIphonePromptLayout>
  );
}
