import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import layoutHeaderImg from "../../assets/layout-header.png";
import CustomButton from "../../components/CustomButton";
export default function PhoneInInterviewEnded() {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          gridArea: "2 / 1 / 3 / 4",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <img
          style={{
            margin: "20px 0",
            maxWidth: "100%",
            maxHeight: "300px",
          }}
          alt="layout-header"
          src={layoutHeaderImg}
        ></img>
      </div>
      <div
        style={{
          gridArea: "2 / 2 / 4 / 3",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          fontSize: "32px",
        }}
      >
        <span style={{ fontSize: "48px" }}>Thank You!</span>
        <br />
        Your
        <br />
        Interview
        <br />
        has
        <br />
        ended
        <br />
      </div>
      <div
        style={{
          gridArea: "2 / 2 / 4 / 3",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <CustomButton
          style={{
            marginBottom: "20px",
            backgroundColor: "#37cb22",
            color: "white",
          }}
        >
          Whatsapp
        </CustomButton>
        <CustomButton
          style={{ marginBottom: "20px", backgroundColor: "#ffdeec" }}
          onClick={() => {
            navigate("/congratualations");
          }}
        >
          Quit
        </CustomButton>
      </div>
    </>
  );
}
