import React, { useState, useEffect } from "react";
import CustomButton from "../../components/CustomButton";
import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import CustomSelect from "../../components/CustomSelect";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import useCandidate from "../../hooks/useCandidate";
import LoadingComponent from "../../components/LoadingComponent";
import updateCandidate from "../../services/updateCandidate";
import { getStoredResponseSheetName, getUID } from "../../utils/authUtils";
export default function PhoneInWillCallYou() {
  const candidate = useCandidate(true);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  useEffect(() => {
    if (!candidate) return;
    if (candidate.interviewStage === "interview") {
      navigate("/goodNews");
    }
  }, [candidate]);
  return (
    <SimpleIphonePromptLayout cardStyle={{ backgroundColor: "#fff2fa" }}>
      <>
        {/* Confirmation Dialog */}
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Completing Job Brief and Interview Tips
          </DialogTitle>
          <DialogContent>
            Are you sure you have completed the Job Brief and Interview Tips?
          </DialogContent>
          <DialogActions>
            <CustomButton
              style={{ backgroundColor: "#ffdeec" }}
              onClick={() => {
                setOpenDialog(false);
                updateCandidate(getStoredResponseSheetName(), getUID(), {
                  interviewStage: "interview",
                });
                navigate("/goodNews");
              }}
            >
              Yes
            </CustomButton>
            <CustomButton
              style={{
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={() => setOpenDialog(false)}
            >
              No
            </CustomButton>
          </DialogActions>
        </Dialog>
        {/* <div style={{ height: "10px" }}></div> */}
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            gridTemplateRows: "auto 1fr auto auto",
            rowGap: "10px",
            alignItems: "center",
          }}
        >
          <CustomButton
            style={{
              color: "var(--primary-color)",
              backgroundColor: "#ffdeec",
            }}
          >
            5 Minute Job Brief and Tips
          </CustomButton>
          <div>
            <div
              style={{
                padding: "20px 0",
                fontWeight: "bold",
                fontSize: "48px",
              }}
            >
              We will
              <br />
              call you in
            </div>

            <CustomButton
              style={{
                backgroundColor: "white",
                border: "3px solid var(--primary-color)",
                color: "var(--focus-primary-color)",
              }}
            >
              {candidate ? (
                candidate.interviewJoinTime
              ) : (
                <LoadingComponent></LoadingComponent>
              )}
            </CustomButton>
          </div>
          <CustomButton
            style={{
              backgroundColor: "var(--primary-color)",
              color: "white",
            }}
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            Completed
          </CustomButton>
          <CustomButton
            onClick={() => navigate("/congratualations")}
            style={{ backgroundColor: "rgb(236,236,236)" }}
          >
            Quit
          </CustomButton>
        </div>
      </>
    </SimpleIphonePromptLayout>
  );
}
