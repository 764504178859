import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import LoadingComponent from "../components/LoadingComponent";
import SimpleIphonePromptLayout from "../layouts/SimpleIphonePromptLayout";
import { getCandidate } from "../services/getCandidate";
import {
  storeCandidate,
  storeResponseSheetName,
  storeUID,
} from "../utils/authUtils";

export default function AuthenticatePage() {
  const navigate = useNavigate();
  let [urlParams, setUrlParams] = useSearchParams();
  const [done, setDone] = useState(false);
  useEffect(() => {
    // TODO: Uncomment
    // localhost:3000/?candidateId=Looz-001&responseSheetName=Phone%20In%20Interview
    const run = async () => {
      const candidateId = urlParams.get("candidateId");
      const responseSheetName = urlParams.get("responseSheetName");
      if (!candidateId || !responseSheetName) {
        return;
      }
      const { data, error } = await getCandidate(
        responseSheetName,
        candidateId
      );

      if (!data || data.length < 1) {
        return;
      }

      storeUID(String(data[0].candidateId));
      storeResponseSheetName(String(responseSheetName));

      // Decide where to send candidate:
      if (data[0].interviewStage === "interview") {
        // Force user to skip pre-interview
        navigate("/actual/interview");
      } else {
        navigate("/congratualations");
      }
    };
    run()
      .then(() => setDone(true))
      .catch(() => setDone(true));
  }, []);
  return (
    <SimpleIphonePromptLayout>
      {done ? (
        <>Please use the link provided in your email to proceed</>
      ) : (
        <LoadingComponent></LoadingComponent>
      )}
    </SimpleIphonePromptLayout>
  );
}
