export default function CustomInput(props: {
  placeholder?: string;
  onChange?: any;
  type: string;
  style?: any;
}) {
  const extraStyles = props.style ? props.style : {}
  return (
    <input
      placeholder={props.placeholder}
      onChange={props.onChange}
      type={props.type}
      style={{
        width: "100%",
        textAlign: "center",
        padding: "10px",
        borderRadius: "15px",
        border: "0 solid rgba(0,0,0,0)",
        outline: "3px solid var(--primary-color)",
        ...extraStyles
      }}
    ></input>
  );
}
