import React from "react";
import { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";

import WalkinLayout from "./layouts/WalkinLayout";

import "bootstrap/dist/css/bootstrap.min.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import * as solidIcons from "@fortawesome/free-solid-svg-icons";
import LoadingComponent from "./components/LoadingComponent";
import * as BrandsIcons from "@fortawesome/free-brands-svg-icons";
import AuthLayout from "./layouts/AuthLayout";
import AuthenticatePage from "./pages/AuthenticatePage";
import PhoneInCongraz from "./pages/phonein/PhoneInCongraz";
import PhoneIn5MinPreInterview from "./pages/phonein/PhoneIn5MinPreInterview";
import PhoneInWillCallYou from "./pages/phonein/PhoneInWillCallYou";
import PhoneInGoodNews from "./pages/phonein/PhoneInGoodNews";
import PhoneInInterviewEnded from "./pages/phonein/PhoneInInterviewEnded";
import PhoneInConsultant from "./pages/phonein/PhoneInConsultant";
import PhoneInActualInterview from "./pages/phonein/ActualInterview/PhoneInActualInterview";
import PhoneInActualInterviewCompletedPrompt from "./pages/phonein/ActualInterview/PhoneInActualInterviewCompletedPrompt";
import PhoneInActualInterviewInterviewerNamePrompt from "./pages/phonein/ActualInterview/PhoneInActualInterviewInterviewerNamePrompt";
import PhoneInActualInterviewWellDone from "./pages/phonein/ActualInterview/PhoneInActualInterviewWellDone";
import PhoneInActualInterviewRating from "./pages/phonein/ActualInterview/PhoneInActualInterviewRating";
import PhoneInActualInterviewFeedback from "./pages/phonein/ActualInterview/PhoneInActualInterviewFeedback";
import PhoneInActualInterviewEnd from "./pages/phonein/ActualInterview/PhoneInActualInterviewEnd";
const solidIconList = Object.keys(solidIcons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon: string) => (solidIcons as any)[icon]);

const brandsIconList = Object.keys(BrandsIcons)
  .filter((key) => key !== "fab" && key !== "prefix")
  .map((icon: string) => (BrandsIcons as any)[icon]);

library.add(...solidIconList);
library.add(...brandsIconList);

const router = createBrowserRouter([
  {
    path: "/",
    element: <WalkinLayout></WalkinLayout>,
    children: [
      {
        path: "",
        element: <AuthenticatePage></AuthenticatePage>,
      },
      {
        path: "*",
        element: <AuthLayout></AuthLayout>,
        children: [
          {
            path: "congratualations",
            element: <PhoneInCongraz></PhoneInCongraz>,
          },
          {
            path: "consultant",
            element: <PhoneInConsultant></PhoneInConsultant>,
          },
          {
            path: "preInterview",
            element: <PhoneIn5MinPreInterview></PhoneIn5MinPreInterview>,
          },
          {
            path: "willCallYou",
            element: <PhoneInWillCallYou></PhoneInWillCallYou>,
          },
          {
            path: "goodNews",
            element: <PhoneInGoodNews></PhoneInGoodNews>,
          },
          {
            path: "end",
            element: <PhoneInInterviewEnded></PhoneInInterviewEnded>,
          },
          {
            path: "actual",
            element: <Outlet></Outlet>,
            children: [
              {
                path: "interview",
                element: <PhoneInActualInterview></PhoneInActualInterview>,
              },
              {
                path: "interviewCompletedPrompt",
                element: (
                  <PhoneInActualInterviewCompletedPrompt></PhoneInActualInterviewCompletedPrompt>
                ),
              },
              {
                path: "interviewerNamePrompt",
                element: (
                  <PhoneInActualInterviewInterviewerNamePrompt></PhoneInActualInterviewInterviewerNamePrompt>
                ),
              },
              {
                path: "welldone",
                element: (
                  <PhoneInActualInterviewWellDone></PhoneInActualInterviewWellDone>
                ),
              },
              {
                path: "rating",
                element: (
                  <PhoneInActualInterviewRating></PhoneInActualInterviewRating>
                ),
              },
              {
                path: "feedback",
                element: (
                  <PhoneInActualInterviewFeedback></PhoneInActualInterviewFeedback>
                ),
              },
              {
                path: "end",
                element: (
                  <PhoneInActualInterviewEnd></PhoneInActualInterviewEnd>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingComponent></LoadingComponent>}>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
