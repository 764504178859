import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import layoutHeaderImg from "../../../assets/layout-header.png";
import { useSearchParams } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import { maxWidth } from "@mui/system";
import updateCandidate from "../../../services/updateCandidate";
import { getStoredResponseSheetName, getUID } from "../../../utils/authUtils";
export default function PhoneInActualInterviewRating() {
  const [rating, setRating] = useState(3);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  let [urlParams, setUrlParams] = useSearchParams();

  const navigate = useNavigate();

  const submitRate = () => {
    updateCandidate(getStoredResponseSheetName(), getUID(), {
      rating: String(rating),
    });
    navigate("/actual/feedback");
  };

  return (
    <>
      <div style={{ gridArea: "2 / 1 / 3 / 4" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={layoutHeaderImg}
            alt="layoutHeaderImg"
            style={{ maxWidth: "100%" }}
          ></img>
        </div>
      </div>
      <div
        style={{ gridColumn: "2 / 3", gridRow: "2 / 4" }}
        className="zoom-out-anim"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "white",
          }}
        >
          <div style={{ fontSize: "2rem", textAlign: "center" }}>How do</div>
          <div style={{ fontSize: "2rem", textAlign: "center" }}>
            you rate your
          </div>
          <div style={{ fontSize: "2rem", textAlign: "center" }}>Interview</div>
          <div
            style={{
              fontSize: "2rem",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Performance?
          </div>
          <div
            style={{
              marginBottom: "30px",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
              columnGap: "10px",
            }}
          >
            {[1, 2, 3, 4, 5].map((x) => (
              <FontAwesomeIcon
                key={`rating-star-${x}`}
                onClick={() => setRating(x)}
                style={{
                  color: rating >= x ? "yellow" : "white",
                  cursor: "pointer",
                }}
                size={"2xl"}
                icon={["fas", "star"]}
              ></FontAwesomeIcon>
            ))}
          </div>
        </div>
      </div>
      <div style={{ gridColumn: "2 / 3" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "10px",
            width: "100%",
            gridColumn: "2 / 3",
            marginBottom: "10px",
          }}
        >
          <CustomButton
            onClick={submitRate}
            style={{
              color: "var(--primary-color)",
              backgroundColor: "#ececef",
            }}
          >
            {submitting ? "Submiting" : "Submit"}
          </CustomButton>
        </div>
      </div>
    </>
  );
}
