import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import layoutHeaderImg from "../../assets/layout-header.png";
import CustomButton from "../../components/CustomButton";
import useCandidate from "../../hooks/useCandidate";
export default function PhoneInGoodNews() {
  const navigate = useNavigate();
  const candidate = useCandidate(true);
  return (
    <>
      <div
        style={{
          gridArea: "2 / 1 / 3 / 4",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <img
          style={{
            margin: "20px 0",
            maxWidth: "100%",
            maxHeight: "300px",
          }}
          alt="layout-header"
          src={layoutHeaderImg}
        ></img>
      </div>
      <div
        style={{
          gridArea: "2 / 2 / 4 / 3",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          fontSize: "32px",
        }}
      >
        <span style={{ fontSize: "48px" }}>Good News!</span>
        <br />
        You have
        <br />
        passed your
        <br />
        "5 Minutes
        <br />
        Job Brief and Tips"
        <br />
      </div>
      <div
        style={{
          gridArea: "2 / 2 / 4 / 3",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        {/* TODO: New page ? */}
        <CustomButton
          onClick={() => {
            if (candidate?.interviewStage === "pre-interview") {
              navigate("/end");
            } else {
              navigate("/actual/interview");
            }
          }}
          style={{
            marginBottom: "20px",
          }}
        >
          Next
        </CustomButton>
        <CustomButton
          style={{ marginBottom: "20px", backgroundColor: "#ffdeec" }}
          onClick={() => {
            navigate("/end");
          }}
        >
          Quit
        </CustomButton>
      </div>
    </>
  );
}
