import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import useConsultants from "../../hooks/useConsultants";
import LoadingComponent from "../../components/LoadingComponent";
export default function PhoneInConsultant() {
  const consultant = useConsultants();
  const navigate = useNavigate();
  return (
    <SimpleIphonePromptLayout>
      {consultant ? (
        <>
          <div style={{ height: "20px" }}></div>

          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "3rem",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div style={{ fontSize: "1.5rem", marginBottom: "10px" }}>
                Your <span style={{ color: "#ff0200" }}>Career Consultant</span>{" "}
                is
              </div>
              <div
                style={{
                  fontSize: "1rem",
                  maxHeight: "200px",
                  maxWidth: "200px",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                <img
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                  alt="consultantImage"
                  src={consultant.imgSrc}
                ></img>
              </div>
              <div>{consultant.name}</div>
            </div>
          </>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "10px",
              width: "100%",
            }}
          >
            <a
              style={{ textDecoration: "none" }}
              target="_blank"
              href={consultant.whatsappHref}
              rel="noreferrer"
            >
              <div
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#38ca1f",
                  borderRadius: "10px",
                  padding: "10px",
                  marginBottom: "20px",
                }}
              >
                Whatsapp
              </div>
            </a>
            <Link style={{ textDecoration: "none" }} to={"/preInterview"}>
              <CustomButton
                style={{
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                }}
              >
                Next
              </CustomButton>
            </Link>
          </div>
        </>
      ) : (
        <LoadingComponent></LoadingComponent>
      )}
    </SimpleIphonePromptLayout>
  );
}
