import rootUrl from "../APIRoot";
import { Candidate } from "../../api/src/interfaces/Candidate";

export async function getCandidate(sheetTitle: string, cid: String): Promise<{
  data: Candidate[];
  error: any;
}> {
  let res = await fetch(`${rootUrl}/candidates/${sheetTitle}/${cid}`);
  let { data, error } = await res.json();
  return { data, error };
}

export async function getAllCandidates(sheetTitle: string): Promise<{
  data: Candidate[];
  error: any;
}> {
  let res = await fetch(`${rootUrl}/candidates/${sheetTitle}`);
  let { data, error } = await res.json();
  return { data, error };
}


