import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import LoadingComponent from "../../../components/LoadingComponent";
import useCandidate from "../../../hooks/useCandidate";
import useConsultants from "../../../hooks/useConsultants";
import SimpleIphonePromptLayout from "../../../layouts/SimpleIphonePromptLayout";

export default function PhoneInActualInterviewCompletedPrompt() {
  const candidate = useCandidate();
  const consultants = useConsultants();
  const navigate = useNavigate();
  return (
    <SimpleIphonePromptLayout>
      {consultants && candidate ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            gridTemplateRows: "1fr auto auto auto",
            rowGap: "10px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: "20px 0",
              fontWeight: "bold",
              fontSize: "32px",

              width: "100%",
            }}
          >
            Have you <br />
            completed
            <br />
            your
            <br />
            interview?
          </div>
          <CustomButton
            style={{ backgroundColor: "var(--primary-color)", color: "white" }}
            onClick={() => {
              navigate("/actual/interviewerNamePrompt");
            }}
          >
            Yes
          </CustomButton>
          <CustomButton
            style={{ backgroundColor: "#ececef" }}
            onClick={() => {
              window.open(
                consultants.whatsappHref +
                  `&text=I am ${candidate?.name}, I did not received interviewer call.`
              );
            }}
          >
            No Call Recevied
          </CustomButton>
          <CustomButton
            style={{ backgroundColor: "#1caf11", color: "#ffd333" }}
            onClick={() => {
              window.open(
                consultants.whatsappHref +
                  `&text=I am ${candidate?.name}, I am facing technical issues.`
              );
            }}
          >
            Technical Issue
          </CustomButton>
        </div>
      ) : (
        <LoadingComponent></LoadingComponent>
      )}
    </SimpleIphonePromptLayout>
  );
}
